import {effect} from 'utils/redux';
import namespace from './namespace';
import * as actions from './actions';
import {decorateWithNotifications, setPageTitleMessage} from 'io/app';
import {change} from 'redux-form';
import services from 'services';
import * as nActions from 'modules/notifications/actions';
import {shortDur, medDur} from 'constants/notifications';
import {
	getOrganizations as getOrganizationsIO,
	getUsers,
	searchCallLogs as searchCallLogsIO,
	getTeams as getTeamsIO,
	fetchTeamsWithUsers as fetchTeamsWithUsersIO,
} from './io';
import {getQuery} from 'io/history';
const creator = effect(namespace);

let intl = null;
services.waitFor('intl').then(x => (intl = x));

export let getAccounts = () => (getState, dispatch) => {
	return decorateWithNotifications(
		{
			id: 'fetch-accounts',
			failureStyle: 'error',
		},
		getUsers(),
	)(getState, dispatch).then(result => {
		dispatch(actions._setUsers(result));
	});
};

getAccounts = creator('getAccounts', getAccounts);

export let getOrganizations = () => (getState, dispatch) => {
	return decorateWithNotifications(
		{
			id: 'fetch-accounts',
			failureStyle: 'error',
		},
		getOrganizationsIO(),
	)(getState, dispatch).then(result => {
		dispatch(actions._setOrganizations(result));
	});
};

getOrganizations = creator('getOrganizations', getOrganizations);

export let getTeams =
	(teamType = 'all') =>
	(getState, dispatch) => {
		const teamTypeQuery = teamType !== 'all' ? `&type=${teamType}` : '';

		return decorateWithNotifications(
			{
				id: 'fetch-teams',
				failuterStyle: 'error',
			},
			getTeamsIO(teamTypeQuery),
		)(getState, dispatch).then(result => {
			dispatch(actions._setTeams(result));
		});
	};

getTeams = creator('getTeams', getTeams);

export let initialize = () => (getState, dispatch) => {
	setPageTitleMessage('Overview [app]')(getState, dispatch);
	getAccounts()(getState, dispatch);
	getOrganizations()(getState, dispatch);
	getTeams()(getState, dispatch);
	dispatch(actions._initialize());
};
initialize = creator('initialize', initialize);

export let searchCallLogs = () => (getState, dispatch) => {
	const q = getQuery();

	/**
	 *
	 * TODO: this should be refactored to provide the query params as an object to the io-function.
	 * Manually constructing the query params is error prone and hard to maintain.
	 */
	if (q?.dateFrom === '') {
		const invalidDateFromMsg =
			intl.formatMessage({id: 'Required field'}) +
			' ' +
			intl.formatMessage({id: 'Date'}) +
			' - ' +
			intl.formatMessage({id: 'From [date from]'});
		dispatch(
			nActions.warning({
				id: 'date-from-required',
				message: invalidDateFromMsg,
				duration: medDur,
			}),
		);
		return;
	}
	if (q?.dateTo === '') {
		const invalidDateToMsg =
			intl.formatMessage({id: 'Required field'}) +
			' ' +
			intl.formatMessage({id: 'Date'}) +
			' - ' +
			intl.formatMessage({id: 'To [date to]'});

		dispatch(
			nActions.warning({
				id: '',
				message: invalidDateToMsg,
				duration: medDur,
			}),
		);
		return;
	}
	const urlParams = {};
	urlParams.createdAtStart = q?.dateFrom + ' 00:00:01';
	urlParams.createdAtEnd = q?.dateTo + ' 23:59:59';
	if (q?.teams !== '' && q?.teams !== 'NaN') urlParams.teams = q?.teams;
	if (q?.users !== '' && q?.users !== 'NaN') urlParams.users = q?.users;
	if (q?.phonenumber !== '') urlParams.phone = q?.phonenumber;
	if (q?._sort !== '') urlParams._sort = q?._sort;
	if (q?._page !== '') urlParams._page = q?._page;
	urlParams.include = 'organization';
	return decorateWithNotifications(
		{
			id: 'search-call-logs',
			failureStyle: 'error',
		},
		searchCallLogsIO(urlParams),
	)(getState, dispatch).then(({data: result, meta}) => {
		if (result.length < 1) {
			dispatch(
				nActions.success({
					id: 'calls-not-found',
					duration: shortDur,
					message: intl.formatMessage({id: 'Calls not found [callLog]'}),
				}),
			);
		}
		dispatch(actions._setCallLogs(result));
		dispatch(actions.setCallLogsPagination(meta?.pagination || {}));
	});
};

searchCallLogs = creator('searchCallLogs', searchCallLogs);

export let setPhonenumber = phonenumber => (getState, dispatch) => {
	dispatch(change('searchCallLogsForm', 'phonenumber', phonenumber));
};
setPhonenumber = creator('setPhonenumber', setPhonenumber);

export let setDateFrom = date => (getState, dispatch) => {
	dispatch(change('searchCallLogsForm', 'dateFrom', date));
};
setDateFrom = creator('setDateFrom', setDateFrom);

export let setDateTo = date => (getState, dispatch) => {
	dispatch(change('searchCallLogsForm', 'dateTo', date));
};
setDateTo = creator('setDateFrom', setDateTo);

export let setUserId = userId => (getState, dispatch) => {
	dispatch(change('searchCallLogsForm', 'users', userId));
};
setUserId = creator('setUserId', setUserId);
export let setTeamId = teamId => (getState, dispatch) => {
	dispatch(change('searchCallLogsForm', 'users', []));
	dispatch(change('searchCallLogsForm', 'teams', teamId));
};
setTeamId = creator('setTeamId', setTeamId);

export let setTeamType = teamType => (getState, dispatch) => {
	dispatch(actions._setTeamType(teamType));
	dispatch(change('searchCallLogsForm', 'teams', []));
	if (teamType === null) getTeams('all')(getState, dispatch);
	if (teamType !== null) getTeams(teamType)(getState, dispatch);
};
setTeamType = creator('setTeamType', setTeamType);

export let fetchTeamsWithUsers = teamIds => (getState, dispatch) => {
	if (!Array.isArray(teamIds)) {
		const invalidTeamIdMsg =
			intl.formatMessage({id: 'Required field'}) +
			' - ' +
			intl.formatMessage({id: 'Team'});
		dispatch(
			nActions.warning({
				id: '',
				message: invalidTeamIdMsg,
				duration: medDur,
			}),
		);
		return;
	}

	if (teamIds.length <= 0) {
		return dispatch(actions._setUsers([]));
	}
	dispatch(change('searchCallLogsForm', 'users', []));
	return decorateWithNotifications(
		{
			id: 'get-team-with-users',
			failureStyle: 'error',
		},
		fetchTeamsWithUsersIO(teamIds.toString()),
	)(getState, dispatch).then(result => {
		const users = [];
		result.forEach(team => {
			team.users?.data.forEach(user => {
				if (!users.some(u => u.id === user.id)) users.push(user);
			});
		});

		dispatch(actions._setUsers(users));
	});
};

fetchTeamsWithUsers = creator('fetchTeamsWithUsers', fetchTeamsWithUsers);
