import {scopedHandler} from 'utils/redux';
import ns from './namespace';
import initState from './state';
import * as actions from './actions';
import * as effects from './effects';
import {decorateHandler as lifecycle} from 'fragments/lifecycle';

const handler = scopedHandler(ns, (state = initState, fullState, {type, payload}) => {
	switch (type) {
		case actions.initialize.type: {
			return [state, effects.initialize()];
		}

		case actions.destroy.type: {
			return [initState, null];
		}
		case actions.searchCallLogs.type: {
			return [{...state}, effects.searchCallLogs()];
		}
		case actions._initialize.type: {
			return [{...state, initialized: true}, null];
		}
		case actions._setCallLogs.type: {
			return [{...state, callLogs: payload, initialized: true}];
		}
		case actions.setCallLogsPagination.type: {
			return [{...state, callLogsPagination: payload}];
		}
		case actions._setUsers.type: {
			return [{...state, users: payload}];
		}
		case actions._setOrganizations.type: {
			return [{...state, organizations: payload}];
		}
		case actions.setPhonenumber.type: {
			return [{...state}, effects.setPhonenumber(payload)];
		}
		case actions.setDateFrom.type: {
			return [{...state}, effects.setDateFrom(payload)];
		}
		case actions.setDateTo.type: {
			return [{...state}, effects.setDateTo(payload)];
		}
		case actions.setUserId.type: {
			return [{...state}, effects.setUserId(payload)];
		}
		case actions.setTeamId.type: {
			return [{...state}, effects.setTeamId(payload)];
		}
		case actions.getTeams.type: {
			return [{...state}, effects.getTeams()];
		}
		case actions._setTeams.type: {
			return [{...state, teams: payload}];
		}
		case actions.setTeamType.type: {
			return [{...state}, effects.setTeamType(payload)];
		}
		case actions._setTeamType.type: {
			return [{...state, teamType: payload}, null];
		}
		case actions.fetchTeamsWithUsers.type: {
			return [{...state}, effects.fetchTeamsWithUsers(payload)];
		}

		default:
			return [state, null];
	}
});

export default lifecycle({
	namespace: ns,
	initializeType: actions.initialize.type,
	destroyType: actions.destroy.type,
})(handler);
